import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { dashboardActions, dashboardSelectors, identitySelectors } from '@appState';
import { Store, select } from '@ngrx/store';
import { CuiDashboardModule, isNotNullOrUndefined } from 'cui-components';
import { Observable, filter, tap } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';
import { MMTileInfo } from 'src/app/app-state/dashboard/dashboard.model';

@Component({
  imports: [CommonModule, RouterModule, CuiDashboardModule, MatProgressSpinnerModule],
  selector: 'ocf-mm-tile',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if ((MMTileInfo$ | async)!; as MMTileInfo) {
      <cui-tile-base [title]="'MM program'" [isLoading]="(MMTileLoading$ | async)!" class="mm-tile">
        <cui-tile-base-body>
          <cui-tile-number
            [title]="'Saved only'"
            [number]="MMTileInfo.savedCount"
            [fontSize]="'2rem'"
            [bgColor]="'var(--cui-color-attention)'"
          ></cui-tile-number>
          <cui-tile-number
            [title]="'In progress'"
            [number]="MMTileInfo.inProgressCount"
            [fontSize]="'2rem'"
            [bgColor]="'var(--color-success)'"
          ></cui-tile-number>
          <cui-tile-number
            [title]="'Ready'"
            [number]="MMTileInfo.readyCount"
            [fontSize]="'2rem'"
            [bgColor]="'var(--cui-color-primary)'"
          ></cui-tile-number>
          <cui-tile-number
            [title]="'Reported'"
            [number]="MMTileInfo.reportedCount"
            [fontSize]="'2rem'"
            [tileLink]="(hasReportedCustomersAccess$ | async)! ? '/reported-customers' : undefined"
            [bgColor]="'var(--cui-color-primary)'"
          ></cui-tile-number>
          <cui-tile-number
            class="next-report-tile"
            [title]="'Next report date'"
            [number]="MMTileInfo.nextReportDate"
            [fontSize]="'1.5rem'"
            [tileLink]="(hasCustomerReportAccess$ | async)! ? '/customer-report' : undefined"
            [bgColor]="'var(--cui-color-primary)'"
          ></cui-tile-number>
        </cui-tile-base-body>
      </cui-tile-base>
    } @else {
      <mat-spinner class="centered" mode="indeterminate" [diameter]="90"></mat-spinner>
    }
  `,
  styles: [
    `
      .centered {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      cui-tile-base-body {
        flex-wrap: wrap;
      }

      cui-tile-number {
        min-width: 6rem;
        &.next-report-tile {
          min-width: 10rem;
        }
      }
    `,
  ],
})
export class MMTileComponent {
  MMTileInfo$: Observable<MMTileInfo> = this.store.pipe(select(dashboardSelectors.selectMMTileInfo), filter(isNotNullOrUndefined));
  MMTileLoading$: Observable<boolean> = this.store.pipe(
    select(dashboardSelectors.selectIsLoading),
    tap(isLoading => {
      if (!isLoading && !this.isObserverCreated) {
        const tileContainer = document.querySelector('cui-tile-base.mm-tile > div.tile-container');
        if (tileContainer) {
          new ResizeObserver(() => {
            const numberTile = document.querySelector('cui-tile-base.mm-tile > div.tile-container > cui-tile-base-body > cui-tile-number');
            const baseBody = document.querySelector('cui-tile-base.mm-tile > div.tile-container > cui-tile-base-body');

            if (numberTile && baseBody) {
              (tileContainer as HTMLElement).style.height = baseBody.clientHeight > numberTile.clientHeight ? 'unset' : '100%';
            }
          }).observe(tileContainer);
        }
      }
    }),
  );
  hasCustomerReportAccess$: Observable<boolean> = this.store.pipe(
    select(identitySelectors.selectHasPermission(['Portal.Business.Dashboard.MM.CustomerReport'])),
  );
  hasReportedCustomersAccess$: Observable<boolean> = this.store.pipe(
    select(identitySelectors.selectHasPermission(['Portal.Business.Dashboard.MM.ReportedCustomers'])),
  );

  isObserverCreated = false;

  constructor(private readonly store: Store<AppState>) {
    this.store.dispatch(dashboardActions.getMMTileInfo());
  }
}
