import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { announcementsActions } from '@appState';
import { Store } from '@ngrx/store';
import { CuiButtonsModule, CuiCardModule, CuiCheckboxComponent, ParseHtmlPipe } from 'cui-components';
import { Announcement } from 'src/app/app-state/announcements/announcements.model';
import { AppState } from 'src/app/app-state/app.store';

@Component({
  imports: [
    CommonModule,
    CuiCardModule,
    CuiButtonsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    CuiCheckboxComponent,
    ParseHtmlPipe,
  ],
  selector: 'ocf-announcement',
  template: ` <cui-card>
    <cui-card-header>
      <div class="flex-end">
        <button class="close-btn" mat-icon-button aria-label="close" (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </cui-card-header>

    <cui-card-body>
      <div *ngIf="announcement" class="rich-text" [innerHTML]="announcement.message | parseHtml"></div>
      <form *ngIf="form" [formGroup]="form">
        <cui-checkbox [label]="announcement.acceptanceText" formControlName="acceptsAnnouncement"></cui-checkbox>
      </form>
    </cui-card-body>
    <cui-card-footer>
      <cui-button (clicked)="close()">Remind Later</cui-button>
      <cui-button-cta [disabled]="form && !form.controls.acceptsAnnouncement.value" (clicked)="close(true)">Close</cui-button-cta>
    </cui-card-footer>
  </cui-card>`,
  styleUrls: ['./announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementDialogComponent implements AfterViewInit {
  form!: FormGroup<{ acceptsAnnouncement: FormControl<boolean> }>;
  announcement: Announcement;

  constructor(
    readonly dialogRef: DialogRef,
    private readonly fb: FormBuilder,
    private readonly store: Store<AppState>,
  ) {
    this.announcement = this.dialogRef.config.data.announcement;

    if (this.announcement.requireAccept) {
      this.form = this.fb.group({
        acceptsAnnouncement: this.fb.control(false, { nonNullable: true }),
      });
    }
  }

  ngAfterViewInit(): void {
    this.store.dispatch(announcementsActions.changeStatus({ id: this.announcement.id, status: 'Remind Later' }));
  }

  close(isAcknowledged = false): void {
    if (isAcknowledged) this.store.dispatch(announcementsActions.changeStatus({ id: this.announcement.id, status: 'Acknowledged' }));

    this.dialogRef.close();
  }
}
